define("ember-metrics/metrics-adapters/base", ["exports", "@ember/debug", "@ember/object/internals", "@ember/utils"], function (_exports, _debug, _internals, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function makeToString(ret) {
    return () => ret;
  }
  class BaseAdapter {
    constructor(config) {
      _defineProperty(this, "metrics", null);
      _defineProperty(this, "config", null);
      this.config = config;
    }
    install() {
      (true && !(false) && (0, _debug.assert)(`[ember-metrics] ${this.toString()} must implement the install hook!`));
    }
    uninstall() {
      (true && !(false) && (0, _debug.assert)(`[ember-metrics] ${this.toString()} must implement the uninstall hook!`));
    }
    toString() {
      const hasToStringExtension = (0, _utils.typeOf)(this.toStringExtension) === 'function';
      const extension = hasToStringExtension ? ':' + this.toStringExtension() : '';
      const ret = `ember-metrics@metrics-adapter:${extension}:${(0, _internals.guidFor)(this)}`;
      this.toString = makeToString(ret);
      return ret;
    }
    identify() {}
    trackEvent() {}
    trackPage() {}
    alias() {}
  }
  _exports.default = BaseAdapter;
  _defineProperty(BaseAdapter, "supportsFastBoot", false);
});